import { gql } from 'graphql-request'

export const ADJUST_COIN_STATUS = gql`
  mutation UpdateTransactionManual(
    $updateTransactionInput: UpdateTransactionInput!
  ) {
    updateTransactionManual(updateTransactionInput: $updateTransactionInput) {
      status
    }
  }
`
