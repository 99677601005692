import { TimeRangeEnum } from '@interfaces/TimeRangeEnum'
import { AdjustCoinStatusSearchForm } from './components/AdjustCoinStatusSearchForm'
import { AdjustCoinStatusSelectTab } from './components/AdjustCoinStatusSelectTab'
import { AdjustCoinStatusPageProps } from './interface'

export function AdjustCoinStatusPage({
  searchParam,
  activeTab,
  page,
  perpage,
  total,
  pageChange,
  handleTotalChange,
  handleSubmit,
  onActiveTabChange,
  handleSort,
}: AdjustCoinStatusPageProps) {
  return (
    <>
      <AdjustCoinStatusSearchForm
        initialValues={{
          searchText: '',
          timeRange: TimeRangeEnum.MONTH,
          startAt: undefined,
          endAt: undefined,
        }}
        handleSubmit={handleSubmit}
      />
      <AdjustCoinStatusSelectTab
        searchParam={searchParam}
        activeTab={activeTab as any}
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
        handleTotalChange={handleTotalChange}
        onActiveTabChange={onActiveTabChange}
        handleSort={handleSort}
      />
    </>
  )
}
