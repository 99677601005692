import { ActionCellProps } from './interface'

export function ActionCell({ handleAdjustStatus }: ActionCellProps) {
  return (
    <button
      className='border border-gray h-[30px] px-[4px] rounded-[8px]'
      type='button'
      onClick={handleAdjustStatus}
    >
      ปรับสถานะ
    </button>
  )
}
