export enum TransactionTypeEnum {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY_STORE = 'GOOGLE_PLAY_STORE',
  CARD = 'CARD',
  QR_PROMPT_PAY = 'QR_PROMPT_PAY',
  DOLFIN_WALLET = 'DOLFIN_WALLET',
  TRUE_MONEY_WALLET = 'TRUE_MONEY_WALLET',
  SHOPEE_PAY = 'SHOPEE_PAY',
  INTERNET_BANKING = 'INTERNET_BANKING',
  PROMOTION = 'PROMOTION',
  CHALLENGE = 'CHALLENGE',
  PURCHASE = 'PURCHASE',
  DONATE = 'DONATE',
}

export const paymentMethodName = {
  [TransactionTypeEnum.APP_STORE]: 'App Store',
  [TransactionTypeEnum.GOOGLE_PLAY_STORE]: 'Google Play Store',
  [TransactionTypeEnum.CARD]: 'Card',
  [TransactionTypeEnum.QR_PROMPT_PAY]: 'QR Prompt Pay',
  [TransactionTypeEnum.DOLFIN_WALLET]: 'Dolfin Wallet',
  [TransactionTypeEnum.TRUE_MONEY_WALLET]: 'True Money Wallet',
  [TransactionTypeEnum.SHOPEE_PAY]: 'Shopee Pay',
  [TransactionTypeEnum.INTERNET_BANKING]: 'Internet Banking',
  [TransactionTypeEnum.PROMOTION]: 'Promotion',
  [TransactionTypeEnum.CHALLENGE]: 'Challenge',
  [TransactionTypeEnum.PURCHASE]: 'Purchase',
  [TransactionTypeEnum.DONATE]: 'Donate',
}

export enum TransactionStatusEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
