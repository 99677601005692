import { AdjustCoinStatusTable } from '../AdjustCoinStatusTable'
import { AdjustCoinStatusSelectTabProps } from './interface'

export function AdjustCoinStatusSelectTab({
  searchParam,
  activeTab,
  page,
  perpage,
  total,
  pageChange,
  handleTotalChange,
  handleSort,
}: AdjustCoinStatusSelectTabProps) {
  return (
    <div className='mt-[45px]'>
      <AdjustCoinStatusTable
        searchParam={searchParam}
        activeTab={activeTab}
        page={page}
        perpage={perpage}
        total={total}
        pageChange={pageChange}
        handleTotalChange={handleTotalChange}
        handleSort={handleSort}
      />
    </div>
  )
}
