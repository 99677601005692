import { AdjustCoinStatusSearchFormProps } from './interface'

export function withAdjustCoinStatusSearchForm(
  Component: React.FC<AdjustCoinStatusSearchFormProps>
) {
  function WithAdjustCoinStatusSearchForm(
    props: AdjustCoinStatusSearchFormProps
  ) {
    return <Component {...props} />
  }

  return WithAdjustCoinStatusSearchForm
}
