import { useState } from 'react'
import { DateTime } from 'luxon'

import { CoinHistoryTypeEnum } from '@interfaces/coin/CoinHistoryTypeEnum'
import { TimeRangeEnum, timeRangeValue } from '@interfaces/TimeRangeEnum'
import { CoinHistorySearchFormType } from '@models/coin/CoinHistorySearchFormType'
import { SortByType, SortingType } from '@components/Table/interface'
import { usePagination } from '@hooks/usePagination'
import {
  AdjustCoinStatusPageProps,
  AdjustCoinStatusQueryParam,
} from './interface'

export function withAdjustCoinStatusPage(
  Component: React.FC<AdjustCoinStatusPageProps>
) {
  function WithAdjustCoinStatusPage() {
    const [activeTab, setActiveTab] = useState(CoinHistoryTypeEnum.ALL)
    const { page, perpage, total, pageChange, handleTotalChange } =
      usePagination({ perpage: 20 })
    const [searchParam, setSearchParam] = useState<AdjustCoinStatusQueryParam>({
      searchText: '',
      timeRange: TimeRangeEnum.MONTH,
      startAt: DateTime.now()
        .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
        .startOf('day'),
      endAt: DateTime.now().endOf('day'),
      sortKey: 'createdAt',
      sortOrder: SortingType.DESC,
    })

    async function handleSubmit({
      searchText,
      timeRange,
      startAt,
      endAt,
    }: CoinHistorySearchFormType) {
      let newStartAt = startAt
        ? DateTime.fromJSDate(startAt).startOf('day')
        : undefined
      let newEndAt = endAt ? DateTime.fromJSDate(endAt).endOf('day') : undefined

      if (timeRange === TimeRangeEnum.DAY) {
        newStartAt = DateTime.now().startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.MONTH) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.MONTH] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      } else if (timeRange === TimeRangeEnum.YEAR) {
        newStartAt = DateTime.now()
          .minus({ day: timeRangeValue[TimeRangeEnum.YEAR] })
          .startOf('day')
        newEndAt = DateTime.now().endOf('day')
      }

      if (newStartAt && newEndAt) {
        const obj = {
          searchText,
          timeRange,
          startAt: newStartAt,
          endAt: newEndAt,
        }

        setSearchParam(prev => ({
          ...prev,
          ...obj,
        }))
        pageChange(1)
      }
    }

    function onActiveTabChange(tabId: string) {
      setActiveTab(tabId as CoinHistoryTypeEnum)
      pageChange(1)
    }

    function handleSort({ key, order }: SortByType) {
      setSearchParam(prev => ({ ...prev, sortKey: key, sortOrder: order }))
    }

    const newProps = {
      searchParam,
      activeTab,
      page,
      perpage,
      total,
      pageChange,
      handleTotalChange,
      onActiveTabChange,
      handleSubmit,
      handleSort,
    }
    return <Component {...newProps} />
  }

  return WithAdjustCoinStatusPage
}
