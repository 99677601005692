import { useClient } from '@hooks/useClient'
import { getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { useMutation } from 'react-query'
import { AcceptActionCellProps, ActionCellProps } from './interface'

export function withActionCell(Component: React.FC<ActionCellProps>) {
  function WithActionCell({
    id,
    transactionId,
    refetchGetCoinToAdjustHistories,
    ...props
  }: AcceptActionCellProps) {
    const client = useClient()
    const alert = useAlert()
    const confirmAdjustStatusModal = useModal({ modal: 'confirm' })

    const { mutate: adjustStatus } = useMutation(
      (recordId: number) => client!.coinClient.adjustCoinStatus(recordId),
      {
        onSuccess: async () => {
          if (refetchGetCoinToAdjustHistories) {
            await refetchGetCoinToAdjustHistories()
            alert.success('ปรับสถานะสำเร็จ')
            confirmAdjustStatusModal.hide()
          }
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
          confirmAdjustStatusModal.hide()
        },
      }
    )
    async function handleAdjustStatus() {
      confirmAdjustStatusModal.show({
        content: (
          <ConfirmModal.Title>
            ยืนยันการปรับสถานะ Transaction Id: {transactionId}
          </ConfirmModal.Title>
        ),
        onConfirm: () => adjustStatus(id),
        onClose: () => confirmAdjustStatusModal.hide(),
      })
    }

    const componentProps = {
      ...props,
      id,
      handleAdjustStatus,
    }

    return <Component {...componentProps} />
  }

  return WithActionCell
}
