import cn from 'classnames'
import { DateTime } from 'luxon'
import { QueryObserverResult } from 'react-query'

import { paymentMethodName } from '@interfaces/coin/TransactionTypeEnum'
import { ColumnType } from '@components/Table/interface'
import { addComma } from '@libs/utils'
import { CoinHistoryDataType } from '@models/coin/CoinHistoryDataType'
import { ActionCell } from './components'

export function CoinCell({
  value,
  className = '',
  isDecimalFormat = false,
}: {
  value: any
  className?: string
  isDecimalFormat?: boolean
}) {
  return (
    <div
      className={cn(
        'text-black-480 text-xs font-light text-right pr-[10px]',
        className
      )}
    >
      {!!value
        ? isDecimalFormat
          ? addComma(value)
          : value.toLocaleString()
        : '-'}
    </div>
  )
}

export function columns({
  refetchGetCoinToAdjustHistories,
}: {
  refetchGetCoinToAdjustHistories: () => Promise<
    QueryObserverResult<any | undefined, unknown>
  >
}): ColumnType<CoinHistoryDataType>[] {
  return [
    {
      column: 'Date & time',
      accessor: 'createdAt',
      cell: ({ value }) => (
        <div className='text-black-480 text-xs font-light'>
          {DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm:ss', {
            locale: 'th-Th',
          })}
        </div>
      ),
      cellClassName: 'border-l border-r py-[5px] pl-[10px] w-[120px]',
      isSortable: true,
    },
    {
      column: <div className='text-center w-full'>Action</div>,
      accessor: 'id',
      cell: ({ value, obj }) => (
        <ActionCell
          id={value}
          transactionId={obj.transactionId}
          refetchGetCoinToAdjustHistories={refetchGetCoinToAdjustHistories}
        />
      ),
      cellClassName: 'border-r py-[5px] text-center w-[100px]',
      isSortable: false,
    },
    {
      column: 'Transaction ID',
      accessor: 'transactionId',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light'>{value}</p>
      ),
      cellClassName: 'border-r py-[5px] px-[10px] w-[100px]',
      isSortable: false,
    },
    {
      column: 'Gateway/Channel',
      accessor: 'paymentMethod',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light'>
          {paymentMethodName[value as keyof typeof paymentMethodName]}
        </p>
      ),
      cellClassName: 'border-r py-[5px] pl-[10px] w-[100px]',
      isSortable: false,
    },
    {
      column: 'User ID',
      accessor: 'user',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light line-clamp-1 break-word'>
          {value.id}
        </p>
      ),
      cellClassName: 'border-r py-[5px] pl-[10px] w-[100px]',
      isSortable: false,
    },
    {
      column: 'User Name',
      accessor: 'user',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light line-clamp-1 break-word'>
          {value.username}
        </p>
      ),
      cellClassName: 'border-r py-[5px] pl-[10px] w-[100px]',
      isSortable: false,
    },
    {
      column: 'Detail',
      accessor: 'description',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light line-clamp-2 break-word leading-[1.8]'>
          {value}
        </p>
      ),
      cellClassName: 'border-r py-[5px] pl-[10px] w-[300px]',
      isSortable: false,
    },
    {
      column: <div className='text-center w-full'>Amount</div>,
      accessor: 'amount',
      cell: ({ value }) => <CoinCell value={value} isDecimalFormat />,
      isSortable: false,
      cellClassName: 'w-[50px] border-r',
    },
    {
      column: <div className='text-center w-full'>สกุลเงิน</div>,
      accessor: 'currency',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light text-right line-clamp-1 break-word'>
          {value}
        </p>
      ),
      cellClassName: 'border-r py-[5px] pr-[10px] w-[50px]',
      isSortable: false,
    },
  ]
}
