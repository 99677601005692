import { gql } from 'graphql-request'

export const COIN_TO_ADJUST_HISTORIES = gql`
  query GetPendingTransactions(
    $limitPerPage: Int
    $page: Int
    $orderBy: TransactionsOrderBy
    $startDate: DateTime
    $endDate: DateTime
    $searchText: String
  ) {
    listTransactions(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      startDate: $startDate
      endDate: $endDate
      searchText: $searchText
      status: PENDING
    ) {
      data {
        id
        userId
        user {
          id
          username
        }
        transactionId
        orderId
        chargeId
        amount
        currency
        description
        status
        paymentMethod
        platform
        failureMessage
        createdAt
        updatedAt
      }
      page
      total
    }
  }
`
