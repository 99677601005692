import { Outlet } from 'react-router-dom'

import { CoinLayout } from '@components/layouts/CoinLayout'
import { CoinBalancePage } from './pages/CoinBalancePage'
import { CoinCreatePage } from './pages/CoinCreatePage'
import { CoinHistoryPage } from './pages/CoinHistoryPage'
import { AdjustCoinStatusPage } from './pages/AdjustCoinStatus'
import { CoinManagementHistoryPage } from './pages/CoinManagementHistoryPage'
import { CoinPage } from './pages/CoinPage'
import { SummaryCoinPage } from './pages/SummaryCoinPage'
import { UploadFilePage } from './pages/UploadFilePage'

export const coinRoutes = [
  {
    path: 'coin',
    element: <Outlet />,
    children: [
      {
        path: 'summary',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: (
              <CoinLayout>
                <SummaryCoinPage />
              </CoinLayout>
            ),
          },
          {
            path: 'coin-balance',
            element: <CoinBalancePage />,
          },
        ],
      },
      {
        path: 'coin-setting',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: (
              <CoinLayout>
                <CoinPage />
              </CoinLayout>
            ),
          },
          {
            path: 'create-coin',
            element: <CoinCreatePage />,
          },
          {
            path: 'history-coin',
            element: <CoinManagementHistoryPage />,
          },
        ],
      },
      {
        path: 'coin-history',
        element: (
          <CoinLayout>
            <CoinHistoryPage />
          </CoinLayout>
        ),
      },
      {
        path: 'upload-file',
        element: (
          <CoinLayout>
            <UploadFilePage />
          </CoinLayout>
        ),
      },
      {
        path: 'coin-status',
        element: (
          <CoinLayout>
            <AdjustCoinStatusPage />
          </CoinLayout>
        ),
      },
    ],
  },
]
