import { Type } from 'class-transformer'
import { AdjustCoinStatusHistoriesDataType } from './AdjustCoinStatusDataType'

export class AdjustCoinStatusDataResponse {
  @Type(() => AdjustCoinStatusHistoriesDataType)
  data: AdjustCoinStatusHistoriesDataType[]

  total: number

  page: number
}
