import { AdjustCoinStatusSelectTabProps } from './interface'

export function withAdjustCoinStatusSelectTab(
  Component: React.FC<AdjustCoinStatusSelectTabProps>
) {
  function WithAdjustCoinStatusSelectTab(
    props: AdjustCoinStatusSelectTabProps
  ) {
    return <Component {...props} />
  }

  return WithAdjustCoinStatusSelectTab
}
