import { gql } from 'graphql-request'

export const PUBLISH_MAIN_BANNER_LIST = gql`
  mutation PublishMainBanners(
    $ids: [Int!]!
    $displayType: MainBannerDisplayType!
  ) {
    publishMainBanners(ids: $ids, displayType: $displayType) {
      id
      web
      android
      ios
      isActiveWeb
      isActiveIos
      isActiveAndroid
      coverImgPath
      linkWeb
      linkIos
      linkAndroid
      description
      startPublishedAt
      endPublishedAt
      runningNo
    }
  }
`
