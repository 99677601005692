import React from 'react'
import { useQuery } from 'react-query'

import { useClient } from '@hooks/useClient'
import {
  AdjustCoinStatusTableAcceptProps,
  AdjustCoinStatusTableProps,
} from './interface'

const withAdjustCoinStatusTable = (
  Component: React.FC<AdjustCoinStatusTableProps>
) => {
  function WithAdjustCoinStatusTable({
    searchParam,
    activeTab,
    page,
    perpage,
    total,
    pageChange,
    handleTotalChange,
    handleSort,
  }: AdjustCoinStatusTableAcceptProps) {
    const client = useClient()

    const queryKey = ['coin-status-list', searchParam, page, activeTab]

    const { data, isLoading, refetch } = useQuery(
      queryKey,
      () =>
        client!.coinClient.getCoinToAdjustData({
          limitPerPage: perpage,
          page,
          searchParam,
        }),
      {
        onSuccess: response => {
          handleTotalChange(response?.total)
        },
      }
    )

    const newProps = {
      data: data?.data ?? [],
      isLoading,
      page,
      perpage,
      total,
      searchParam,
      pageChange,
      handleSort,
      refetch,
    }
    return <Component {...newProps} />
  }

  return WithAdjustCoinStatusTable
}

export default withAdjustCoinStatusTable
