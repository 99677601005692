import { Expose, Type } from 'class-transformer'
import {
  TransactionStatusEnum,
  TransactionTypeEnum,
} from '@interfaces/coin/TransactionTypeEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'

export class UserType {
  @Expose() id: number

  @Expose() username: string
}

export class AdjustCoinStatusHistoriesDataType {
  @Expose() id: number

  @Expose() userId: number

  @Expose() orderId: number

  @Expose() chargeId: number

  @Expose() description: string

  @Expose() platform: PlatformEnum

  @Expose() status: TransactionStatusEnum

  @Expose() paymentMethod: TransactionTypeEnum

  @Expose() failureMessage: string

  @Expose() amount: number

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose() currency: string

  @Expose() transactionId: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}
